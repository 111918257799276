import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout-blog.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`PCMH Restore Health successfully conducted an influenza immunization camp at Indiranagar today. 20 people were vaccinated in this camp. Thanks to Dr BC Rao for providing the venue and spreading the word.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      